@import 'src/styles/variables';

@mixin icon {
    font-size: 24px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.iconSuccess {
    @include icon;
    color: $success;
}

.iconAwaiting {
}

.iconError {
    @include icon;
    color: $error;
}

.iconWarning {
    @include icon;
    color: $warning;
}

.iconNotice {
    @include icon;
    color: $notice;
}
