@import '../../../styles/variables';

.applicantName {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
}

.applicantEmail {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
}

.applicantRoles {
    font-size: 14px;
    line-height: 20px;
}

.applicationGuid {
    :global {
        .ant-typography {
            font-size: 12px;
            line-height: 1.2em;
            display: block;
            margin-top: 0.5em;
        }
    }
}
