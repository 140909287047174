$main-color: #000000;
$sec-color: #ffffff;
$tert-color: #666666;
$quate-color: #0000008c;
$mute-color: rgb(157 157 157);
$error-color: rgba(255, 0, 0, 0.3);
$main-font: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$accent-color: #3f803f;
$base-font-size: 16px;
$base-lineheight: 28px;
$sm: 541px;
$md: 769px;
$lg: 1100px;
$xlg: 1440px;
$base-box-shadow: 0 1px 2px 0 rgb(82 90 104 / 20%);
$header-bg: #f3f3f4;
$success: #52c41a;
$error: #ff4d4f;
$warning: #faad14;
$notice: #337ab7;
$validation-error: #f5222d;
