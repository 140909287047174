.langSwitching {
    display: inline-flex;

    :global {
        .ant-dropdown-trigger {
            padding: 4px 10px;

            .ant-avatar {
                top: -1px;
                position: relative;
            }
        }

        .ant-dropdown-open {
            .anticon-down {
                transform: rotate(180deg);
            }
        }

        .anticon-down {
            top: 1px;
            position: relative;
        }
    }
}

.langSwitchingMenu {
    :global {
        .ant-dropdown-menu-item .ant-avatar-image {
            top: -1px;
        }
    }
}
