$menu-selected-bg: transparent;
$menu-active-bg: transparent;
$menu-selected-link-color: #337ab7;
$menu-hover-link-color: #337ab7;

@import 'src/styles/variables';
@import 'src/styles/mixins';

:global {
    .ant-menu-overflow {
        @include responsive-down($sm) {
            flex-wrap: wrap;
        }
    }

    .ant-menu-horizontal > .ant-menu-item a:hover {
        color: $menu-hover-link-color;
    }

    .ant-menu-submenu .ant-menu {
        .ant-menu-item:is(:active),
        .ant-menu-submenu-title:is(:active) {
            background-color: $menu-active-bg;
        }

        .ant-menu-item-selected {
            background-color: $menu-selected-bg;

            a {
                color: $menu-selected-link-color;
            }
        }
    }
}


