.wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 50vw;
}

.line {
    align-items: baseline;
    display: flex;
    flex-basis: 100%;
    margin-top: 5px;
    margin-bottom: 5px;

    :global {
        .ant-upload-list-item-card-actions {
            display: none;
        }

        .ant-upload-list-item-info {
            display: inline-flex;
        }
    }
}

.uploadButton {
    margin-right: 20px;
    max-width: 250px;
}

.errMessage {
    color: #ff4d4f;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    span {
        padding: 0 5px;
        flex-basis: 100%;
    }
}

.errItem {
    width: 100%;
}

.errBlock {
    display: block;
}
