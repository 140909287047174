.pageHeaderUtility {
    &.pageHeaderUtility {
        padding-top: 0;
    }

    :global {
        .ant-page-header-heading-title label {
            font-weight: 400;
        }

        .ant-page-header-heading,
        .ant-page-header-heading-left,
        .ant-page-header-heading-title {
            width: 100%;
        }
    }
}
