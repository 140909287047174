@import '../../../../styles/variables';

.subTitle {
    color: $quate-color;
    font-weight: 500;
}

.item {
}

.subTitle,
.item {
    display: block;
    margin: 5px 0;
}
