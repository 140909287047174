@import 'src/styles/variables';

.code {
    color: $accent-color;
    max-width: 25vw;
}

:global {
    .verror {
        .ant-table-cell {
            background: $error-color !important;
        }
    }
}
