@import '../../styles/variables';

.info {
    font-size: 13px;
}

.link {
    display: block;
}

.row {
    display: flex;
}

.contacts {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -15px;
}

.item {
    flex: 1 0 50%;
    padding: 10px 15px;
    position: relative;

    &:not(:last-child):after {
        content: '';
        width: 1px;
        position: absolute;
        bottom: 10px;
        top: 10px;
        right: 0;
        background: #d9d9d9;
    }

    @media (max-width: 767px) {
        flex-basis: 100%;

        &:not(:last-child) {
            margin-bottom: -10px;

            &:after {
                display: none;
            }
        }
    }
}

.label {
    margin-right: 5px;
}

.restoreLink {
    margin-top: 10px;
    margin-bottom: 10px;
}

.specModule {
    margin-top: 10px;
}

.language {
    display: flex;
    justify-content: center;
    margin: 13px 0 -6px;

    :global {
        .ant-dropdown-trigger {
            border: 1px solid rgb(219 219 219);
        }
    }
}

.vision {
    margin-right: 10px;
}
