.showTable {
    width: 100%;
    td,
    th {
        border: 1px solid #f0f0f0;
        overflow-wrap: break-word;
        padding: 16px;
        position: relative;
        background: #fafafa;
    }

    table {
        width: 100%;
    }
}

.pageHeading {
    align-items: center;
    display: flex;
    margin: 4px 0 20px;
    overflow: hidden;
    span {
        color: rgba(0, 0, 0, 0.85);
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 0;
        margin-right: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.pageContent {
}
