.formItem {
    &:global {
        &.ant-form-item-has-error .ant-form-item-control-input {
            outline: 0;
            background-color: #fff;
            border: 1px solid #f5222d;
        }
    }
}

.holder {
    width: 100%;

    :global {
        .ant-space-item {
            width: 100%;
        }
    }
}
