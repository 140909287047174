.cardList {
    :global {
        .ant-card-body {
            height: 200px;
            overflow: hidden;
        }
    }
}

.card {
    &:after {
        content: '';
        height: 50%;
        background-image: linear-gradient(
            to top,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0)
        );
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
