@import 'src/styles/variables';

.layout {
    background: $sec-color;
}

.container {
    max-width: 480px;
    margin: auto;
    box-shadow: $base-box-shadow;
    border: 1px solid #dbdbde;
}

.title.title {
    color: #626262;
    display: flex;
    font-size: 16px;
    text-align: center;
    align-items: center;

    img {
        display: block;
        height: auto;
        position: relative;
        left: -5px;
    }
}

.top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    color: $sec-color;
    font-size: 24px;
    font-weight: bold;
}

.contentBlock {
    background-color: #f3f3f4;

    :global {
        .ant-card-head {
            padding: 0 17px;
        }

        .ant-card-head-title {
            white-space: normal;
        }

        .ant-card-body {
            padding: 24px 17px;
            margin-top: -40px;
        }
    }
}

.horLine {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $accent-color;
    margin-top: 10px;
}

.titleContent {
    width: 100%;
}
