@import 'src/styles/variables';
@import 'src/styles/mixins';

* {
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
}

body {
    font-family: $main-font;
    font-size: $base-font-size;
    line-height: $base-lineheight;
}

.ant-layout {
    background: $sec-color;
}

.ant-page-header {
    padding: 16px 5px;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
}

.ant-table-wrapper {
    .align-top {
        vertical-align: top;
    }
}

.ant-layout-content {
    .container {
        .ant-space {
            flex-wrap: wrap;
        }
    }
}

body.vision {
    a,
    input,
    button,
    .slick-prev,
    .slick-next {
        &:focus {
            outline-offset: 1px;
            outline: 2px dotted #000 !important;
        }
    }

    *:not(img) {
        color: #000000 !important;
        font-weight: 700;
        border-radius: 0 !important;
        border-color: #000 !important;
        transition: 0s linear !important;
        background: #fff !important;
    }

    img,
    svg {
        filter: grayscale(100%);
    }

    svg {
        fill: #000 !important;
    }

    .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        color: #000000 !important;
        font-weight: 700;
        border-radius: 0 !important;
        border-color: #000 !important;
        transition: 0s linear !important;
        background: #fff !important;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
    .ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-submenu-selected::after {
        border-color: #000000 !important;
    }

    .ant-form .ant-input:focus,
    .ant-form .ant-input-focused,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: none !important;
    }

    .ant-spin-dot-item {
        background: #000000 !important;
    }
}

.ant-notification-notice {
    min-height: 64px;
}
