.visionMode {
    position: relative;
    transition-delay: 5s !important;
    line-height: 1;

    @media (min-width: 768px) {
        &:hover {
            transition-delay: 0s !important;

            .dropdownMenu {
                display: flex;
            }
        }

        .trigger {
            &:hover + .dropdownMenu {
                display: flex;
            }
        }
    }

    @media (max-width: 767px) {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: self-start;
    }
}

.label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--carbone100) !important;
    margin: 0.45em 0;
    padding: 0.5em 0.5em;

    :global {
        .anticon {
            top: 1px;
            position: relative;
            left: 2px;
            font-size: 19px;
        }
    }

    &:hover {
        color: var(--carbone100) !important;
    }
}

.trigger {
    .anticon {
        position: relative;
        top: 1px;
    }

    @media (max-width: 767px) {
        display: none !important;
    }
}

.menuItem {
    font-size: 14px;
    line-height: 1.25em;
    padding: 0.5em 0.7em;
    color: rgb(0 0 0 / 65%);
    transition: all 0.3s;

    :global {
        .anticon {
            font-size: 13px;
        }
    }

    &:not(:last-child) {
        margin-bottom: 0.15em;
    }

    &:hover {
        color: #337ab7;
        background: #f5f5f5;
    }
}

.dropdownMenu {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    flex-direction: column;
    background: #fff;
    display: none;
    position: absolute;
    left: 0;
    width: 230px;
    z-index: 3;

    &.posTop {
        top: 30px;
    }

    &.posBottom {
        flex-direction: column-reverse;
        bottom: 30px;
    }

    @media (max-width: 767px) {
        background: transparent;
    }
}

.dropdownMenuOpen {
    display: flex;
}

.collapsedView {
    background: transparent;
    box-shadow: none;
    margin-left: 40px;
    position: static;

    .menuItem {
        padding: 0.5em;
        color: var(--carbone100);
        letter-spacing: -0.03em;
        font-size: 15px;

        &:hover {
            color: var(--carbone100);
        }
    }
}
